import React, { useEffect, useState } from "react";
// import { CONVEX_API_URL } from "../../../../../config/config";
import BlpHeroPic from "../../../../images/csm/blp-hero-pic.png";
import linkedinPic from "../../../../images/csm/linkdein-blp-heropic.png";
import message from "../../../../images/csm/messageBlp.png";
import starPic from "../../../../images/csm/starBlp.png";
import badge from "../../../../images/csm/badgeBlp.png";
import "./styles.scss";
import call from "../../../../images/csm/phone-call-icon.png";
import emailpic from "../../../../images/csm/messages-icon.png";
import blpplogo from "../../../../images/csm/blpp-logo.png";
import quoteblp from "../../../../images/csm/quote-blp.png";
import growth from "../../../../images/csm/growth-blp.png";
import leftline from "../../../../images/csm/left-dot-blp.png";
import rightline from "../../../../images/csm/right-dot-blp.png";
import MobileStepper from "@mui/material/MobileStepper";
import sandClock from "../../../../images/Bilingual/icons/sandclockIcon.png";
import CalenderIcon from "../../../../images/Bilingual/icons/calenderIcon.png";
import JobIcon from "../../../../images/Bilingual/icons/jobIcon.png";
import HigestCtc from "../../../../images/Bilingual/icons/ectc.png";
import avgCtc from "../../../../images/Bilingual/icons/actc.png";
import Mode from "../../../../images/Bilingual/icons/mode.png";
import { CONVEX_API_URL } from "../../../../../config/config";
import { useLocation } from "@reach/router";
import Alert from '@mui/material/Alert';

// import { graphql, useStaticQuery } from "gatsby";

function ThankYou({ formfields, id }) {
  const { name, phone, email, counselorName, counselorPhone } =
    formfields ?? {};
  const location = useLocation();
  const isCreated = location?.search.includes("existingEmail") ? true : false;

  // Function to extract the id from the query string and remove the trailing '/'
  const getIdFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    let id = searchParams.get("id");

    if (id && id.endsWith("/")) {
      id = id.slice(0, -1); // Remove the trailing '/'
    }

    return id;
  };

  const leadId = getIdFromUrl();

  const [step, setstep] = useState(1);

  const [cvFilled, setcvFilled] = useState(false);
  const [errors, setErrors] = useState({});
  const [placeholderText, setPlaceholderText] = useState("Upload PDF");
  const [leadName, setleadName] = useState();
  const [councName, setcouncName] = useState();
  const [councNumber, setcouncNumber] = useState();
  const [alertMessage, setAlertMessage] = useState('');
  const[cvdata ,setCvdata]=useState('');
  const [showAlert, setShowAlert] = useState(false); 

  const [formData, setFormData] = useState({
    graduationYear: "",
    marksInGraduation: "",
    yearofworkExp: "",
    city: "",
    domain: "",
    file: "",
    interested: "",
  });

  const validateForm = () => {
    let newErrors = {};

    if (
      !formData.graduationYear ||
      formData.graduationYear === "Select Your passing Year"
    ) {
      newErrors.graduationYear = "Graduation year required";
    }

    if (!formData.marksInGraduation) {
      newErrors.marksInGraduation = "Marks required";
    }

    if (!formData.yearofworkExp || formData.yearofworkExp === "select Any") {
      newErrors.yearofworkExp = "Years of work-ex required";
    }

    if (!formData.city) {
      newErrors.city = "City is required";
    }

    if (!formData.domain || formData.domain === "select Any") {
      newErrors.domain = "Domain is required";
    }

    if (!formData.file || !formData.file.endsWith(".pdf")) {
      newErrors.file = "CV is required and must be a PDF";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  //getting details of the lead
  useEffect(() => {
    getLeaddata();
  }, []);

  // fetching the details of the lead
  const getLeaddata = async () => {
    try {
      const url = `${CONVEX_API_URL}/lead/${leadId}/details/show`;
      const result = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const data = await result.json();

      if (data?.LeadDetailsModel?.id) {
        setleadName(data?.name);

        setcouncName(data?.CouncelorModel?.name);

        setcouncNumber(data?.CouncelorModel?.phone);

        setcvFilled(true);

        setstep(2);
      } else {
        setleadName(data?.name);

        setcvFilled(false);
      }
    } catch (e) {
      console.error(e, "error coming while fetching lead details");
    }
  };

  // handle chhange function for inputs
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Specific validation for "interested" field
    if (name === "interested") {
      // Check if the length exceeds 1000 characters
      if (value.length > 1000) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          interested: "The answer should be less than 1000 characters.",
        }));
      } else {
        // If not exceeding, remove the error if it exists
        setErrors((prevErrors) => {
          const { interested, ...otherErrors } = prevErrors;

          return otherErrors;
        });
      }
    }

    // Update form data for any field
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeFile = async (e) => {
    const file = e.target.files[0]; // Get the selected file from the input

    if (file) {
      // Check if the uploaded file is a PDF
      if (!file.name.endsWith(".pdf")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          file: "CV must be a PDF file.",
        }));

        return;
      }

      setPlaceholderText(file.name); // Update placeholder to the file name

      const formData = new FormData();

      // Append file and folder to formData
      formData.append("file", file);
      formData.append("folder", "lead-details");

      try {
        const response = await fetch(
          "https://service.kraftshala.com/storage/add-external?timestamp=1724739629058",
          {
            method: "POST",
            headers: {
              Authorization:
                "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJpc2hpa2VzaEBrcmFmdHNoYWxhLmNvbSIsInR5cGUiOiJVc2VyTG9naW4iLCJpYXQiOjE3MjQ3MzM3MDYsImV4cCI6MTcyNDc5MTMwNn0.QuDY91HJWMowFgBm8H04jVYY9xOaH7W3GTD7AKx32ow",
            },
            body: formData, // Use the FormData object here
          }
        );

        const result = await response.json();

        setCvdata(result);

        setFormData((prevData) => ({
          ...prevData,
          file: result.url,
        }));

        if (result.url) {
          setAlertMessage('CV uploaded successfully');
        } else {
          setAlertMessage('Error in uploading CV. Please try again');
        }

        setShowAlert(true);
          
        // Clear the file-related error, if any
        setErrors((prevErrors) => {
          const { file, ...otherErrors } = prevErrors;

          return otherErrors;
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      setPlaceholderText("Upload PDF"); // Reset placeholder if no file
    }
  };

  // useEffect to hide the alert automatically after 3 seconds
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false); 
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleSubmit = async (event) => {
    // setFormData("");
    event.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      /// for storing cv form datat we need to call api here
      try {
        const leadDetailsData = {
          lead_id: leadId,
          dob: new Date().toISOString(),
          passing_year: formData?.graduationYear,
          work_experience: Number(formData?.yearofworkExp),
          marks_in_grad: Number(formData?.marksInGraduation),
          pin_code: formData?.city,
          domain: formData?.domain,
          cv_link: formData?.file,
          interest_to_apply: formData?.interested,
        };
        const url = `${CONVEX_API_URL}/lead/${leadId}/details`;
        const result = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(leadDetailsData),
        });
        const data = await result.json();

        setstep(2);
      } catch (error) {
        console.error("Error in submitting:", error);
      }
    } else {
      console.log("Form Validation Failed");
    }
  };

  return (
    <div className="thankyou-wrapper-blp">
      { showAlert &&(
        <div
          style={{
            position: 'fixed',
            top: '40px',
            right: '40px',
            zIndex: 1000, 
            width: 'auto',
          }}
        >
          <Alert
            variant="filled"
            severity={cvdata?.url ? 'success' : 'error'}
           
          >
            {alertMessage}
          </Alert>
        </div>
      )}
      <img className="left-dot-line" src={leftline} loading="lazy"   style={{ transform: step === 2 ? "translateY(-48em)" : "translateY(-35em)" }}/>
      <img className="right-dot-line" src={rightline} loading="lazy" style={{ transform: step === 2 ? "translateY(-50em)" : "translateY(-38em)" }}/>
      <div className="blp-heroheader-div">
        <h2>Welcome {isCreated ? `back ${leadName}` : leadName}!</h2>
        {isCreated && step === 1 && !cvFilled ? (
          <p className="complete-your-app">
            Complete your Application to move ahead :)
          </p>
        ) : (
          ""
        )}

        <div className="blp-sub-heroheader-div">
          <img src={blpplogo} loading="lazy" />
          <h2>PGP in Sales and Business Leadership</h2>
          <p>
            {" "}
            with <b>INDUSTRY</b>Creds<sup>TM</sup> Certification
          </p>
        </div>
        <MobileStepper
          variant="progress"
          steps={2}
          position="static"
          activeStep={step}
          sx={{
            maxWidth: 970,
            minWidth: 570,
            // flexGrow: 1,
            backgroundColor: "#fff",
            marginLeft: "50%",
          }}
          LinearProgressProps={{
            sx: {
              backgroundColor: "#D9D9D9",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#3DBD69", // Ensure green color is applied
              },
            },
            value: step === 1 ? 50 : 100, // 50% for step 1, 100% for step 2
            variant: "determinate", // Use determinate variant to control the progress value
          }}
        />
      </div>

      <div className="blp-thankyou-body-div">
        <div className="body-div-left">
          <div className="text-div">
            <div className="text-div-1">
              <img src={quoteblp} loading="lazy" />
              <p>
                I was very confused whether I should go for an MBA. And if i
                didn't get an IIM, then ROI is not that good. As opposed to
                that, Kraftshala was offering{" "}
                <b>much better ROI on my time and money. </b>
              </p>
            </div>
            <div className="text-div-2">
              <p>
                I am so glad i took the decision as I am now placed at Nestlé
                which was a dream company for me!
              </p>
            </div>
            <div className="text-div-3">
              <b>
                Abhishek Nimawat
                <span>
                  <a target="_blank"  href="https://www.linkedin.com/in/abhisheknimawat/"
                  >
                    <img
                      className="linkdein-pic"
                      src={linkedinPic}
                      loading="lazy"
                    
                    />
                  </a>
                </span>
              </b>
              <div className="abhishek-nimawat">
                <b>Sales Executive trainee, Nestlé</b>
                <p>Ex-Biotech graduate</p>
              </div>
            </div>
          </div>
          <div className="image-div">
            <img src={BlpHeroPic} loading="lazy" alt="heroPic" />
          </div>
        </div>

        <div
          className="body-div-right"
          style={{ height: errors ? "auto" : "650px" }}
        >
          {step === 1 && !cvFilled ? (
            <>
              <img
                className="growth-pic"
                src={growth}
                loading="lazy"
                alt="growthpic"
              />
              <form className="form-wrapper" onSubmit={handleSubmit}>
                <div className="form-div-1">
                  <label>
                    {" "}
                    <span>
                      Graduation Year<sup>*</sup>
                    </span>

                    <div className="select-box">             
                      <select
                        name="graduationYear"
                        value={formData.graduationYear}
                        onChange={handleChange}
                      >
                        <option value="Select Your passing Year">
                        Select Your Passing Year
                        </option>
                        <option value="2020 or earlier">2020 or earlier</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025 or after">2025 or after</option>
                      </select>
                    </div>

                    {errors?.graduationYear && (
                      <p className="graduationYear">{errors.graduationYear}</p>
                    )}
                  </label>
                  <label>
                    {" "}
                    <span>
                      Marks in graduation<sup>*</sup>
                    </span>
                    <input
                      name="marksInGraduation"
                      placeholder="Type Percentage"
                      value={formData.marksInGraduation}
                      onChange={handleChange}
                      type="number"
                    />
                    {errors?.marksInGraduation && (
                      <p className="marksInGraduation">
                        {errors.marksInGraduation}
                      </p>
                    )}
                  </label>
                </div>

                <div className="form-div-2">
                  <label>
                    {" "}
                    <span>
                      Years of work-ex<sup>*</sup>
                    </span>

                    <div className="select-box">
                      <select
                        className="yearofworkExp"
                        name="yearofworkExp"
                        value={formData.yearofworkExp}
                        onChange={handleChange}
                      >
                        <option value="Select any">Select Any</option>
                        <option value="0">Fresher</option>
                        <option value="1">0-2 Years</option>
                        <option value="3">2-4 Years</option>
                        <option value="4">Above 4 years</option>
                      </select>
                    </div>
                    {errors?.yearofworkExp && (
                      <p className="yearofworkExp">{errors.yearofworkExp}</p>
                    )}
                  </label>
                  <label>
                    {" "}
                    <span>
                      City <sup>*</sup>
                    </span>
                    <input
                      name="city"
                      placeholder="Your City"
                      value={formData.city}
                      onChange={handleChange}
                      type=""
                    />
                    {errors?.city && <p className="city">{errors.city}</p>}
                  </label>
                </div>

                <div className="form-div-3">
                  <label>
                    {" "}
                    <span>
                      Which domain are you interested in?<sup>*</sup>
                    </span>

                    <div className="select-box">
                      <select
                        name="domain"
                        value={formData.domain}
                        onChange={handleChange}
                      >
                        <option value="Select any">Select Any</option>
                        <option value="FMCG (Fast-moving Consumer Goods)">
                        FMCG (Fast-moving Consumer Goods)
                        </option>
                        <option value="B2B SaaS (Business to Business - Software as a Service)">
                        B2B SaaS (Business to Business - Software as a Service)
                        </option>
                        <option value="Open to exploring both">
                        Open to exploring both
                        </option>
                      </select>
                    </div>
                    {errors?.domain && (
                      <p className="domain">{errors.domain}</p>
                    )}
                  </label>
                  <label style={{ position: "relative" }} className="cv-label">
                    <span>
                      Upload your CV<sup>*</sup>
                    </span>
                    <div
                      style={{
                        position: "relative",
                        border: "0.5px solid #C4C4C4",
                        padding: "10.5px 12px",
                        cursor: "pointer",
                        height: "47px",
                        lineHeight: "24px",
                      }}
                    >
                      <span
                        style={
                          {
                            zIndex: 1000,
                            cursor: "pointer"
                           
                          }
                        }
                      >
                        {placeholderText}
                      </span>
                      <input
                        type="file"
                        name="file"
                        accept=".pdf" // Restrict file types here
                        onChange={handleChangeFile}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: -1,
                        }}
                      />
                    </div>
                    {errors?.file && <p className="file">{errors.file}</p>}
                  </label>

                  <label>
                    {" "}
                    <span>
                      What got you interested to apply for the program?
                      
                    </span>
                    <input
                      placeholder="Tell us in a few words :)"
                      name="interested"
                      value={formData.interested}
                      onChange={handleChange}
                      style={{height:"70px", paddingTop:"0px"}}
                    />
                    {errors?.interested && (
                      <p className="interested">{errors.interested}</p>
                    )}
                  </label>
                  <div className="Next-button">
                    <button type="submit">Next <span> {">"}</span></button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <div className="right-div-step-2-wrapper">
              <div className="admission-cell-div">
                {isCreated && cvFilled
                  ? "Next step: Get in touch with your counselor"
                  : !isCreated && !cvFilled
                    ? "Next step: 1-1 Admission Call"
                    : isCreated && !cvFilled
                      ? "Next step: 1-1 Admission Call"
                      : "Next step: 1-1 Admission Call"}
              </div>

              {isCreated && cvFilled ? (
                <div className="counselor-div">
                  <p>
                    You can call / message your counselor <br />
                    {councName ?? "Khyati k"} on{" "}
                    {councNumber ?? "+91-9508031771"} <br /> for next steps{" "}
                  </p>
                </div>
              ) : !isCreated && !cvFilled ? (
                <div className="receive-call-div">
                  <h2 className="receive-call">
                    You will receive a call in 24-48 hrs
                  </h2>
                  <p className="mandatory-step">
                    This is a mandatory step to get inside Kraftshala
                  </p>
                </div>
              ) : isCreated && !cvFilled ? (
                <div className="receive-call-div">
                  <h2 className="receive-call">
                    You will receive a call in 24-48 hrs
                  </h2>
                  <p className="mandatory-step">
                    This is a mandatory step to get inside Kraftshala
                  </p>
                </div>
              ) : (
                <div className="receive-call-div">
                  <h2 className="receive-call">
                    You will receive a call in 24-48 hrs
                  </h2>
                  <p className="mandatory-step">
                    This is a mandatory step to get inside Kraftshala
                  </p>
                </div>
              )}

              <div className="what-to-expect-div">
                <div>
                  <h2>What to expect?</h2>
                  <p className="chill-text">
                    {" "}
                    Chill. You dont have to prepare for this!
                  </p>
                </div>

                <div className="icons-texts">
                  <div>
                    <img src={message} loading="lazy" />
                    <p>
                      Discuss if the program is a fit basis your profile and
                      career goals
                    </p>
                  </div>

                  <div>
                    <img src={starPic} className="star-icon" loading="lazy" />
                    <p>Get answers to your questions about Kraftshala</p>
                  </div>

                  <div>
                    <img src={badge} loading="lazy" />
                    <p>
                      If our goals align, <b>book your Bar Raiser Interview </b>
                      (which will be the final Round of Admission)
                    </p>
                  </div>
                </div>
              </div>
              <img className="growth-icon-2" src={growth} loading="=lazy" />
            </div>
          )}
        </div>
      </div>
      {step === 2 ? (
        <div className="footer1-for-step2">
          <h4>About the 1-1 Admissions Call</h4>
          <p>
            Kraftshala programs are designed to
            get you <b style={{textDecoration:"underline"}}>hired.</b> We do not accept applicants who we can’t give an
            extremely high ROI to, period.{" "}
          </p>
          <p>
            {" "}
            This call with our PGP Admissions Lead will allows us to measure how
            much impact can Kraftshala have on the the outcomes you want to
            achieve. If our goals converge, we’ll schedule the next round for
            you.
          </p>
        </div>
      ) : (
        ""
      )}

      {step === 2 ? (
        <div className="footer2-for-step2">
          <h4>SOME MORE READING LINKS</h4>
          <div className="links-div">
            <div className="links-div1">
              <div className="text-div">
                See curriculum, projects, outcomes and community{" "}
              </div>

              <div className="click-div">
                <a
                  href="https://go.kraftshala.com/PG_Program_in_Sales_and_Business_Leadership"
                  target="_blank"
                >
                  <p>View Program Brochure</p>
                  <p>{">"}</p>
                </a>
              </div>
            </div>

            <div className="links-div2">
              <div className="text-div">
                Read more on the admissions process and selection philosophy{" "}
              </div>
              <div className="click-div">
                <a
                  href="https://www.kraftshala.com/blog/admission-process-of-pgp-in-sales-and-business-leadership/"
                  target="_blank"
                >
                  <p>Prep Document</p>
                  <p>{">"}</p>
                </a>
              </div>
            </div>

            <div className="links-div3">
              <div className="text-div">
                Get a flavour of the program experience from current students{" "}
              </div>
              <div className="click-div">
                <a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MDM4OTcxNzgzODkzOTE4?story_media_id=3308864982863127834_8091193586&igsh=NXRmdDV0Yndhb2Vo" target="_blank">
                  <p>PGP Experience</p>
                  <p>{">"}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <Floater className="blp-thankyou-overview" /> */}
      {/* floater section  */}
      {step === 1 && (
        <div className="page-floater-basl-thankyou">
          <div className="floater-content">
            <img className="floater-icon-basl" src={sandClock} loading="lazy" />
            <div className="floater-title">Program Duration </div>
            <div className="floater-sub">8 Months</div>
          </div>
          <div className="floater-content">
            <img className="floater-icon-basl" src={JobIcon} loading="lazy" />
            <div className="floater-title">LIVE Projects</div>
            <div className="floater-sub">with Nestlé & Turbohire</div>
          </div>
          <div className="floater-content">
            <img className="floater-icon-basl" src={HigestCtc} loading="lazy" />
            <div className="floater-title"> Highest CTC</div>
            <div className="floater-sub">of ₹17.2 LPA</div>
          </div>

          <div className="floater-content">
            <img className="floater-icon-basl" src={avgCtc} loading="lazy" />
            <div className="floater-title"> Average CTC</div>
            <div className="floater-sub">of ₹11.2 LPA</div>
          </div>

          <div className="floater-content">
            <img className="floater-icon-basl" src={Mode} loading="lazy" />
            <div className="floater-title">Mode</div>
            <div className="floater-sub">
              Online,
              <br className="pc-hide" /> Full-Time
            </div>
          </div>
          <div className="floater-content">
            <img
              className="floater-icon-basl"
              src={CalenderIcon}
              loading="lazy"
            />
            <div className="floater-title"> Batch of 2025</div>
            <div className="floater-sub">Starts in 14 Jan</div>
          </div>
        </div>
      )}
      {/* floater section end */}
      <div className="blp-footer-3">
        <div className="left-div-f3">
          <h4>Have questions?</h4>
          <p>Avaliable from 10AM - 7 PM, Monday-Friday</p>
        </div>
        <div className="right-div-f3">
          <div>
            <img src={call} loading="=lazy" />
            <h4>+91-7827135490</h4>
          </div>
          <div>
            <div>
              <img src={emailpic} loading="=lazy" />
              <h4 className="mail-underline">
                <a href="mailto:contact@kraftshala.com">
                  contact@kraftshala.com
                </a>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
